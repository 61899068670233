export const SUBJECT = {
    RUSSAN: 'russian',
    MATH: 'math',
    CHESS: 'chess',
    LOGOPEDIST: 'logopedist',
    CONVERSATION: 'conversation',
};

export const SUBJECT_TITLE = {
    [SUBJECT.RUSSAN]: 'Русский язык и литература',
    [SUBJECT.MATH]: 'Математика',
    [SUBJECT.CHESS]: 'Шахматы',
    [SUBJECT.LOGOPEDIST]: 'Онлайн-логопед',
    [SUBJECT.CONVERSATION]: 'Разговорный клуб',
};

export const PAGE = {
    MAIN: 'main',
    RUSSIAN: 'russian',
    MATH: 'math',
    CHESS: 'chess',
    LOGOPEDIST: 'logopedist',
    CONVERSATION: 'conversation',
    PRICES: 'prices'
};

export const PAGE_TITLE = {
    [PAGE.MAIN]: 'Главная',
    [PAGE.RUSSIAN]: 'Русский язык и литература',
    [PAGE.MATH]: 'Математика',
    [PAGE.CHESS]: 'Шахматы',
    [PAGE.LOGOPEDIST]: 'Онлайн-логопед',
    [PAGE.CONVERSATION]: 'Разговорный клуб',
    [PAGE.PRICES]: 'Цены'
};

export const AGE = {
    upToSix: '6<',
    overSix: '>6'
};

export const AGE_TITLE = {
    [AGE.upToSix]: 'До 6 лет',
    [AGE.overSix]: 'От 6 лет',
};

export const GROUP = {
    GROUP: 'group',
    INDIVIDUAL: 'individual'
};

export const GROUP_TITLE = {
    [GROUP.GROUP]: 'Занятия в группе',
    [GROUP.INDIVIDUAL]: 'Индивидуальные занятия',
};