import axios from '../../plugins/axios';

const EMPTY = {
    name: '',
    link: '',
    screenshot: '',
    enabled: true,
};

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: JSON.parse(JSON.stringify(EMPTY))
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = JSON.parse(JSON.stringify(EMPTY));
        },
    },

    actions: {
        async fetch({ commit }, { filter = {}, sorting = null }) {
            const { data } = await axios.get('/admin/videos', { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/admin/videos/${ id }`);
            commit('SET_ENTITY', data);
        },
        async setPosition({ commit, state }, { id, position }) {
            await axios.put(`/admin/videos/position/${ id }/${ position }`);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity._id) {
                response = await axios.put(`/admin/videos/${ state.entity._id }`, state.entity);
            } else {
                response = await axios.post('/admin/videos', state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/admin/videos/${ id }`);
        },
    },

    getters: {}
}
