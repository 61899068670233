import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: {
            name: 'feedbacks-list'
        }
    }, {
        path: '/user/authorization',
        name: 'user-authorization',
        component: () => import('../views/user/Authorization.vue')
    }, {
        path: '/user/confirmation',
        name: 'user-confirmation',
        component: () => import('../views/user/Confirmation.vue')
    }, {
        path: '/feedbacks',
        name: 'feedbacks-list',
        component: () => import('../views/feedbacks/List.vue')
    }, {
        path: '/teachers',
        name: 'teachers-list',
        component: () => import('../views/teachers/List.vue')
    }, {
        path: '/teachers/create',
        name: 'teachers-create',
        component: () => import('../views/teachers/Edit.vue')
    }, {
        path: '/teachers/edit/:id',
        name: 'teachers-edit',
        component: () => import('../views/teachers/Edit.vue')
    }, {
        path: '/prices',
        name: 'prices-list',
        component: () => import('../views/prices/List.vue')
    }, {
        path: '/prices/create',
        name: 'prices-create',
        component: () => import('../views/prices/Edit.vue')
    }, {
        path: '/prices/edit/:id',
        name: 'prices-edit',
        component: () => import('../views/prices/Edit.vue')
    }, {
        path: '/titles',
        name: 'titles-list',
        component: () => import('../views/titles/List.vue')
    }, {
        path: '/titles/create',
        name: 'titles-create',
        component: () => import('../views/titles/Edit.vue')
    }, {
        path: '/titles/edit/:id',
        name: 'titles-edit',
        component: () => import('../views/titles/Edit.vue')
    }, {
        path: '/questions',
        name: 'questions-list',
        component: () => import('../views/questions/List.vue')
    }, {
        path: '/questions/create',
        name: 'questions-create',
        component: () => import('../views/questions/Edit.vue')
    }, {
        path: '/questions/edit/:id',
        name: 'questions-edit',
        component: () => import('../views/questions/Edit.vue')
    }, {
        path: '/reviews',
        name: 'reviews-list',
        component: () => import('../views/reviews/List.vue')
    }, {
        path: '/reviews/create',
        name: 'reviews-create',
        component: () => import('../views/reviews/Edit.vue')
    }, {
        path: '/reviews/edit/:id',
        name: 'reviews-edit',
        component: () => import('../views/reviews/Edit.vue')
    }, {
        path: '/videos',
        name: 'videos-list',
        component: () => import('../views/videos/List.vue')
    }, {
        path: '/videos/create',
        name: 'videos-create',
        component: () => import('../views/videos/Edit.vue')
    }, {
        path: '/videos/edit/:id',
        name: 'videos-edit',
        component: () => import('../views/videos/Edit.vue')
    },
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
