import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import files from './modules/files';

import feedbacks from './modules/feedbacks';
import teachers from './modules/teachers';
import prices from './modules/prices';
import titles from './modules/titles';
import questions from './modules/questions';
import reviews from './modules/reviews';
import videos from './modules/videos';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        files,
        feedbacks,
        teachers,
        prices,
        titles,
        questions,
        reviews,
        videos
    }
})
