<template>
    <v-app>
        <v-app-bar
            app
            color="primary"
            dark
            absolute
            elevate-on-scroll
        >
            <v-app-bar-nav-icon 
                v-if="user.id"
                @click.stop="drawer = !drawer" />
        </v-app-bar>

        <v-main>
            <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
            >
                <v-list
                    nav
                    dense
                >
                    <v-list-item-group>
                        <v-list-item  v-if="user.admin" @click="$router.push({ name: 'feedbacks-list' })">
                            <v-list-item-title>Заявки</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item  v-if="user.admin" @click="$router.push({ name: 'teachers-list' })">
                            <v-list-item-title>Преподаватели</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item  v-if="user.admin" @click="$router.push({ name: 'prices-list' })">
                            <v-list-item-title>Цены</v-list-item-title>
                        </v-list-item>
                        <v-list-item  v-if="user.admin" @click="$router.push({ name: 'titles-list' })">
                            <v-list-item-title>Заголовки</v-list-item-title>
                        </v-list-item>
                        <v-list-item  v-if="user.admin" @click="$router.push({ name: 'questions-list' })">
                            <v-list-item-title>Вопросы</v-list-item-title>
                        </v-list-item>
                        <v-list-item  v-if="user.admin" @click="$router.push({ name: 'reviews-list' })">
                            <v-list-item-title>Отзывы</v-list-item-title>
                        </v-list-item>
                        <v-list-item  v-if="user.admin" @click="$router.push({ name: 'videos-list' })">
                            <v-list-item-title>Видео</v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item @click="logout">
                            <v-list-item-title>Выйти</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <div class="ma-4">
                <router-view />
            </div>
        </v-main>
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    export default {
        name: 'GetSkills',
        metaInfo: {
            title: 'GetSkills',
            titleTemplate: '%s | GetSkills'
        },
        data: () => ({
            drawer: null,
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        async mounted() {
            if(!this.user.id) {
                await store.dispatch('auth/fetch');
                if (!this.user.id) {
                    await this.$router.push({ name: 'user-authorization' });
                }
            }
        },
        methods: {
            async logout() {
                await store.dispatch('auth/logout');
                await this.$router.push({ name: 'user-authorization' });
            }
        }
    };
</script>