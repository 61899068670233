import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VAppBar,{attrs:{"app":"","color":"primary","dark":"","absolute":"","elevate-on-scroll":""}},[(_vm.user.id)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e()],1),_c(VMain,[_c(VNavigationDrawer,{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,[(_vm.user.admin)?_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'feedbacks-list' })}}},[_c(VListItemTitle,[_vm._v("Заявки")])],1):_vm._e(),_c(VDivider),(_vm.user.admin)?_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'teachers-list' })}}},[_c(VListItemTitle,[_vm._v("Преподаватели")])],1):_vm._e(),_c(VDivider),(_vm.user.admin)?_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'prices-list' })}}},[_c(VListItemTitle,[_vm._v("Цены")])],1):_vm._e(),(_vm.user.admin)?_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'titles-list' })}}},[_c(VListItemTitle,[_vm._v("Заголовки")])],1):_vm._e(),(_vm.user.admin)?_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'questions-list' })}}},[_c(VListItemTitle,[_vm._v("Вопросы")])],1):_vm._e(),(_vm.user.admin)?_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'reviews-list' })}}},[_c(VListItemTitle,[_vm._v("Отзывы")])],1):_vm._e(),(_vm.user.admin)?_c(VListItem,{on:{"click":function($event){return _vm.$router.push({ name: 'videos-list' })}}},[_c(VListItemTitle,[_vm._v("Видео")])],1):_vm._e(),_c(VDivider),_c(VListItem,{on:{"click":_vm.logout}},[_c(VListItemTitle,[_vm._v("Выйти")])],1)],1)],1)],1),_c('div',{staticClass:"ma-4"},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }